import { ICompareWithSelection } from '@mg-platform/core/core-data-access'
import { IMonthlyGoal } from '@mg-platform/reports/reports-data-access'

export interface IUpdateForecastRequest extends Partial<ICompareWithSelection> {
  monthWorkingDays: number
}

export interface ITargetsForecastResponse {
  monthGoal: IMonthlyGoal
}
