import { Component, Input, OnChanges } from '@angular/core'
import { CommonService } from '@mg-platform/core/core-util'
import { UntilDestroy } from '@ngneat/until-destroy'

export interface IPieChartItem {
  value: number
  colorClass: string
  gradiantStart: string
  gradiantEnd: string
  isGradiant?: boolean
  dasharray?: number
  dashoffset?: number
}

@UntilDestroy()
@Component({
  selector: 'mg-ring-chart',
  templateUrl: './ring-chart.component.html',
  styleUrls: ['./ring-chart.component.scss']
})
export class RingChartComponent implements OnChanges {
  @Input() size = 200
  @Input() ringWidth = 10
  @Input() items: IPieChartItem[] = []

  radius = 0
  randomId = ''

  constructor(private commonService: CommonService) {}

  ngOnChanges(): void {
    this.randomId = this.commonService.generateRandomString()
    this.radius = this.size / 2 - this.ringWidth / 2

    this.items = this.items
      .map((el) => {
        const dasharray = 2 * 3.14 * this.radius

        const result = [
          {
            ...el,
            value: el.value > 100 ? 100 : el.value,
            dasharray,
            dashoffset: dasharray * ((100 - el.value) / 100)
          }
        ]

        if (el.value > 100) {
          const extendValue = el.value - 100
          result.push({
            ...el,
            isGradiant: true,
            value: extendValue,
            dasharray,
            dashoffset: dasharray * ((100 - extendValue) / 100)
          })
        }

        return result
      })
      .flat()
  }
}
