import { IPaginationResponse } from '@mg-platform/core/core-data-access'

export interface IAdminMarketPricingItem {
  laborRate: number
  alignment: number
  oilChange: number
  shopId: string
  shopName: string
  organizationName: string
}

export interface IAdminMarketPricingResponse {
  pagination: IPaginationResponse
  items: IAdminMarketPricingItem[]
}
