import { MarketType } from '@mg-platform/reports/reports-data-access'

export interface IEnterpriseMarketPricingsRequest {
  includeNational: boolean
  stateIds?: string[]
  regionIds?: string[]
}

export interface IEnterpriseMarketPricingsDetail {
  marketType: MarketType
  stateId?: string
  stateName?: string
  regionId?: string
  regionName?: string
  value: number
  graphValue: number
  color: string
}

export interface IEnterpriseMarketPricingsResponse {
  laborRates: IEnterpriseMarketPricingsDetail[]
  alignments: IEnterpriseMarketPricingsDetail[]
  oilChanges: IEnterpriseMarketPricingsDetail[]
}
