import { IPaginationRequest, IPaginationResponse } from '@mg-platform/core/core-data-access'
import { IAdminSimpleShop } from '@mg-platform/shops/shops-data-access'
import { IBaseUser } from '@mg-platform/users/users-data-access'
import { NotificationChannel } from '../enums/notification-channel.enum'
import { NotificationType } from '../enums/notification-type.enum'

export interface INotificationRequest extends IPaginationRequest {
  type?: NotificationType
  channel?: NotificationChannel
}

export interface INotificationItem {
  id: string
  type: NotificationType
  channel: NotificationChannel
  title: string
  body: string
  shop: IAdminSimpleShop
  user: IBaseUser
  created: Date
}

export interface IAllNotificationsResponse {
  pagination: IPaginationResponse
  notifications: INotificationItem[]
}
