import { ColorType, IIncludedShops } from '@mg-platform/core/core-data-access'

export interface ITargetsHistoryItem {
  label: string
  monthLabel: string
  month: number
  actualWorkingDays: number
  goalWorkingDays: number
  formattedActual: string
  actual: number
  formattedActualPerDay: string
  actualPerDay: number
  formattedGoal: string
  goal: number
  formattedGoalPerDay: string
  goalPerDay: number
  percent: number
  formattedPercent: string
  color: ColorType
}

export interface ITargetsHistoryResponse {
  sales: ITargetsHistoryItem[]
  carCount: ITargetsHistoryItem[]
  includedShops: IIncludedShops[]
}
