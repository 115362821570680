import {
  ICompTrendsBaseData,
  ICompTrendsBaseHeader,
  ICompTrendsBaseItem
} from '@mg-platform/core/core-data-access'
import { MarketType } from '@mg-platform/reports/reports-data-access'

export interface IEnterpriseTrendsRequest {
  includeNational: boolean
  stateIds?: string[]
  regionIds?: string[]
}

export interface IEnterpriseTrendHeader extends ICompTrendsBaseHeader {
  marketType: MarketType
  stateId?: string
  stateName?: string
  regionId?: string
  regionName?: string
}

export interface IEnterpriseTrendItem extends ICompTrendsBaseItem {
  header: IEnterpriseTrendHeader
  data: ICompTrendsBaseData[]
}

export interface IEnterpriseTrendDetail {
  graphs4Weeks: IEnterpriseTrendItem[]
  graphs8Weeks: IEnterpriseTrendItem[]
  graphs12Weeks: IEnterpriseTrendItem[]
}

export interface IEnterpriseTrendResponse {
  avgSales: IEnterpriseTrendDetail
  avgCarCount: IEnterpriseTrendDetail
  avgTicket: IEnterpriseTrendDetail
}
