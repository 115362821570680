<div class="mb-base flex flex-col md:flex-row md:items-end">
  <div class="flex-1 flex flex-wrap gap-sm">
    <ng-container *ngFor="let filter of filters">
      <div
        *ngFor="let text of filter.texts; let i = index"
        class="flex items-center px-base py-xs rounded-full border border-txt-secondary text-base leading-base text-txt-secondary"
      >
        <span>
          {{ text }}
        </span>
        <div class="flex ml-sm cursor-pointer" (click)="removeItem(filter.key, i)">
          <svg-icon key="close" class="!text-xs"></svg-icon>
        </div>
      </div>
    </ng-container>
  </div>

  <mg-button
    *ngIf="showExport"
    class="block self-end mt-base md:mt-0"
    icon="export"
    variant="ghost"
    [loading]="exportLoading"
    extraBtnClass="{{ exportLoading ? 'bg-ui-page !py-0' : '!py-0' }}"
    (click)="exportClicked.emit()"
  >
    Export to CSV
  </mg-button>
</div>
