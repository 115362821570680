export interface ITargetsItem {
  month: number
  monthName?: string
  workingDays?: number
  actualSales?: number
  actualCars?: number
  sales: number
  cars: number
}

export interface ITargetsUpdateRequest {
  currentValues: ITargetsItem[]
}

export interface ITargetsResponse {
  previousYearValues: Required<ITargetsItem>[]
  currentYearValues: ITargetsItem[]
}

export interface ITargetsItemWithPercentage extends ITargetsItem {
  salesPercentage?: number
  carsPercentage?: number
}

export interface ITargetsInfo {
  previousYearValues: ITargetsItemWithPercentage
  currentYearValues: ITargetsItemWithPercentage
}
