import { IPaginationResponse } from '@mg-platform/core/core-data-access'
import { IAdminMarketPricingItem } from '../../interfaces/admin-market-pricings.interface'
import {
  IMarketPricingResponse,
  IMarketPricingThresholds
} from '../../interfaces/market-pricings.interface'

export interface MarketPricingReportsStateModel {
  marketPricing: IMarketPricingResponse
  marketPricingChanged: boolean
  thresholds: IMarketPricingThresholds
  allMarketPricings: IAdminMarketPricingItem[]
  paginationInfo: IPaginationResponse
}
