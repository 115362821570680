import { ITargetsInfo, ITargetsResponse } from '../../interfaces/targets.interface'
import { ITargetsHistoryResponse } from '../../interfaces/targets-history.interface'
import { ITargetsForecastResponse } from '../../interfaces/targets-forecast.interface'

export interface TargetsStateModel {
  targets: ITargetsResponse
  targetsInfo: ITargetsInfo[]

  targetsHistory: ITargetsHistoryResponse
  targetsForecast: ITargetsForecastResponse
}
