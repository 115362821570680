<div class="flex items-center">
  <div class="flex-1">
    <div class="text-lg leading-lg font-medium">
      @if (title) {
        {{ title }}
      } @else {
        <ng-content select="[slot=title]"></ng-content>
      }
    </div>

    <div class="mt-2xs text-base leading-base">
      @if (subTitle) {
        {{ subTitle }}
      } @else {
        <ng-content select="[slot=subTitle]"></ng-content>
      }
    </div>
  </div>

  <mg-button
    *ngIf="action"
    variant="ghost"
    class="flex"
    size="small-shrink"
    [icon]="action.icon"
    iconPosition="right"
    [routerLink]="action.link"
    (click)="actionClicked.emit()"
  >
    <span
      class="lowercase first-letter:uppercase"
      [ngClass]="{ 'hidden md:inline-block': !action.showTitleOnMobile }"
    >
      {{ action.title }}
    </span>
  </mg-button>
</div>
