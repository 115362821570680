import { IPaginationResponse } from '@mg-platform/core/core-data-access'
import { IAdminShop, IAdminSimpleShop } from '../../interfaces/shop.interface'

export interface AdminShopsStateModel {
  shop: IAdminShop
  allShops: IAdminSimpleShop[]
  verifiedPaginationInfo: IPaginationResponse
  ignoredPaginationInfo: IPaginationResponse
  pendingPaginationInfo: IPaginationResponse
}
