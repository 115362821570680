import { DayOfWeek } from '@mg-platform/core/core-data-access'
import { IOrganizationBase } from '@mg-platform/organizations/organizations-data-access'
import { IBaseUser, UserRoleInShop } from '@mg-platform/users/users-data-access'
import { ShopPermissionType } from '../enums/shop-permission-type.enum'
import { ShopFocusType } from '../enums/shop-focus-type.enum'
import { PosType } from '@mg-platform/admin-reports/admin-reports-data-access'
import { IShopGroup } from './shop-groups.interface'

export interface IShop {
  id: string
  nickname?: string
  name: string
  displayName: string
  fullAddress?: string
  organizationName?: string
  phoneNumber: string
  websiteUrl: string
  numberOfBays: number
  isVerified: boolean
  verified?: Date
  lastBackToBackFilledDailyReportDay?: Date
  workingDays?: DayOfWeek[]
  organization?: IOrganizationBase
  lastFilledReportDate?: Date
  created: Date
  stateName?: string
  regionName?: string
  focus?: ShopFocusType
  posType?: PosType
  stateId?: string
}

export interface IShopResponse {
  myShops: IShop[]
  organizationShops: IShop[]
  groups: IShopGroup[]
}

export interface IAdminShop extends IShop {
  address: string
  city: string
  postCode: string
  state: string
  latitude: number
  longitude: number
  permissions: ShopPermissionType
  ignored: boolean
  users: IBaseUser[]
}

export interface ISimpleShop {
  id: string
  name: string
  nickname?: string
  displayName: string
  address?: string
  roleInShop?: UserRoleInShop
}

export interface IAdminSimpleShop {
  id: string
  name: string
  nickname: string
  fullAddress: string
  contact?: string
  organizationName: string
  isVerified: boolean
  usersCount: number
  roleInShop: UserRoleInShop
  lastFilledReportDate?: Date
  created: Date
}
