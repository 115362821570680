import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'
import { ITableAction, ITableFIlterNotifier, TableColumnType } from '../../table-filter.interface'
import { TableOperatorToSentence } from '../../table-filter.constant'
import { PeriodType, PeriodTypeToLabel } from '@mg-platform/core/core-data-access'
import { formatDate } from '@angular/common'

interface IAppliedTableFilter {
  key: string
  texts?: string[]
}

@UntilDestroy()
@Component({
  selector: 'mg-table-filters-list',
  templateUrl: './table-filters-list.component.html',
  styleUrls: ['./table-filters-list.component.scss']
})
export class TableFiltersListComponent implements OnInit {
  @Input() tableActions: ITableAction
  @Input() showExport = true
  @Input() exportLoading = false
  @Output() filterRemoved = new EventEmitter<ITableFIlterNotifier>()
  @Output() exportClicked = new EventEmitter()

  filters: IAppliedTableFilter[] = []

  ngOnInit(): void {
    if (this.tableActions.filters) {
      this.filters = Object.values(this.tableActions.filters)?.map((el) => {
        const texts = el.items?.map((item) => {
          let text = `${el.label} `
          switch (el.columnType) {
            case TableColumnType.date:
              if (item.value === PeriodType.Custom) {
                text += `from ${formatDate(item.extraValue.from, 'mediumDate', 'en-US')} to ${formatDate(item.extraValue.to, 'mediumDate', 'en-US')}`
              } else {
                text += `${TableOperatorToSentence[item.operator]} ${PeriodTypeToLabel[item.value]}`
              }

              break

            default:
              text += `${TableOperatorToSentence[item.operator]} ${item.value}`
              break
          }
          return text
        })

        return {
          key: el.key,
          texts
        }
      })
    }
  }

  removeItem(key: string, index: number) {
    if (!this.tableActions.filters) {
      return
    }
    const filter = this.tableActions.filters[key]

    this.filterRemoved.emit({
      filter: {
        ...filter,
        items: filter.items?.filter((el, i) => i !== index)
      }
    })
  }
}
