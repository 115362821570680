<div class="relative flex items-center justify-center" [style.height.px]="size" [style.width.px]="size">
  <div class="absolute left-0 top-0" *ngFor="let item of items">
    <svg
      [attr.width]="size"
      [attr.height]="size"
      style="transform: rotate(-90deg)"
      class="{{ !item.isGradiant ? item.colorClass : '' }}"
    >
      <linearGradient
        *ngIf="item.isGradiant"
        id="gradientColor{{ randomId }}"
        x1="0%"
        y1="0%"
        x2="0%"
        y2="100%"
      >
        <stop offset="60%" [attr.stop-color]="item.gradiantStart" />
        <stop offset="100%" [attr.stop-color]="item.gradiantEnd" />
      </linearGradient>
      <circle
        [attr.r]="radius"
        cx="50%"
        cy="50%"
        stroke-linecap="round"
        fill="transparent"
        [attr.stroke]="!item.isGradiant ? 'currentColor' : 'url(#gradientColor' + randomId + ')'"
        [attr.stroke-width]="ringWidth + 'px'"
        [attr.stroke-dasharray]="item.dasharray"
        [attr.stroke-dashoffset]="item.dashoffset"
      ></circle>
    </svg>
  </div>

  <ng-content></ng-content>
</div>
