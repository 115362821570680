import { IPaginationResponse } from '@mg-platform/core/core-data-access'
import { IOrganizationBase } from '@mg-platform/organizations/organizations-data-access'
import { IAdminSimpleShop } from '@mg-platform/shops/shops-data-access'
import { IBaseUser } from './user.interface'

export interface IAdminUserItem extends IBaseUser {
  firstName: string
  lastName: string
  organizationName?: string
  numberOfShops: number
  created: Date
  lastLoginTime: Date
  lastLogoutTime: Date
}

export interface IAdminAllUsersResponse {
  pagination: IPaginationResponse
  users: IAdminUserItem[]
}

export interface IAdminUserDetails extends IBaseUser {
  organization?: IOrganizationBase
  shops?: IAdminSimpleShop[]
}

export interface IUpdateAdminUserRequest {
  firstName: string
  lastName: string
  phoneNumber: string
  newEmail: string
}
