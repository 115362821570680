import { BenchmarkType, MarketType, TrendsType } from '@mg-platform/reports/reports-data-access'

export interface IEnterpiseBenchmarkRequest {
  includeNational: boolean
  stateIds?: string[]
  regionIds?: string[]
  type: BenchmarkType
  fromDate?: Date
  toDate?: Date
}

export interface IEnterpiseBenchmarkDetail {
  marketType: MarketType
  stateId?: string
  stateName?: string
  regionId?: string
  regionName?: string
  value: number
  graphValue: number
  color: string
}

export interface IEnterpiseBenchmarkItem {
  type: TrendsType
  benchmarks: IEnterpiseBenchmarkDetail[]
}

export interface IEnterpiseBenchmarkResponse {
  data: IEnterpiseBenchmarkItem[]
}
